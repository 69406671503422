<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <h4>amiibo cards tracker <span class="grayText">v0.1</span></h4>
      </div>
      <div class="col-md-6" style="text-align: right;">
        <div class="marquee">
        <span><i class="bi bi-box2-heart"></i> Your collection: {{ collection.length }}</span> |
        <span><i class="bi bi-box2-heart"></i> Unique amiibos: {{ calcUniqueAmiibos() }}</span> |
        <span><i class="bi bi-box2-heart"></i> Repeating amiibos: {{ collection.length - calcUniqueAmiibos() }}</span><br>
        <span>Collection updated: {{ lastUpdated }}</span> |
        <span v-if="this.data"><i class="bi bi-grid-3x3-gap"></i> Total amiibos in DB: {{  this.data.length }}</span>
        <span></span>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div style="text-align: center;">
          <input class="form-control form-control-lg" type="text" placeholder="Search" v-model="search"><br>
          <span class="filterLink" v-on:click="search = ''"> <i class="bi bi-grid-3x3-gap"></i> All</span> |
          <span class="filterLink" v-on:click="search = 'Collected'"> <i class="bi bi-box2-heart"></i> Collected</span> |
          <span class="filterLink" v-on:click="search = 'Repeating'"> <i class="bi bi-copy"></i> Repeating</span> |
          <span class="filterLink" v-on:click="search = 'Missing'"> <i class="bi bi-search-heart"></i> Missing</span> |

          <span class="filterLink" v-on:click="search = 'Series 1'">Series 1</span> |
          <span class="filterLink" v-on:click="search = 'Series 2'">Series 2</span> |
          <span class="filterLink" v-on:click="search = 'Series 3'">Series 3</span> |
          <span class="filterLink" v-on:click="search = 'Series 4'">Series 4</span> |
          <span class="filterLink" v-on:click="search = 'Series 5'">Series 5</span> |
          <span class="filterLink" v-on:click="search = 'Sanrio'">Sanrio</span> |
          <span class="filterLink" v-on:click="search = 'Welcome Amiibo'">Welcome Amiibo</span> |
          <span class="filterLink" v-on:click="search = 'Specials'">Specials</span> |
          <span class="filterLink" v-on:click="search = 'Amiibo Figures'">Amiibo Figures</span>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="container" v-if="this.data">
    <div class="row">
      <div class="col-md-2" v-for="item in matchSearch(this.data,this.search)" v-bind:key="item">
        <div class="card">
          <img
            v-bind:src="'images/'+item.id+'.png'"
            class="card-img-top"
            v-bind:alt="item.name"
            v-if="collection.includes(item.id)"
            v-on:click="addToCollection(item.id)"
          />
          <img
            v-bind:src="'images/'+item.id+'.png'"
            class="card-img-top grayscale"
            v-bind:alt="item.name"
            v-on:click="addToCollection(item.id)"
            v-else
          />
          <div class="card-body">
                <span class="grayText">{{ formatId(item.id).toUpperCase() }}</span> {{ item.name }}<br>
                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-sm btn-secondary" v-on:click="removeFromCollection(item.id)" ><i class="bi bi-dash-circle"></i></button>
                  <button type="button" class="btn btn-sm btn-secondary" disabled>{{ countInCollection(item.id) }}</button>
                  <button type="button" class="btn btn-sm btn-secondary" v-on:click="addToCollection(item.id)"><i class="bi bi-plus-circle"></i></button>
                </div>
          </div>
        </div>
      </div>

      <div class="col-md-12" v-if="matchSearch(this.data,this.search).length == 0">
        <div class="alert alert-warning" role="alert">
          No results found
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "AmiiboCardsTracker",
  data() {
    return {
      data: null,
      collection: [],
      search: "",
      lastUpdated: "never",
    };
  },
  mounted() {
    // fetch json from local file
    this.lastUpdated = this.dateToAgo(localStorage.getItem('last_updated')) || "never"
    if(this.lastUpdated != "never"){
      // run every 5s
      setInterval(() => {
        this.lastUpdated = this.dateToAgo(localStorage.getItem('last_updated'))
      }, 5000)
    }

    fetch("main.json")
      .then((response) => response.json())
      .then((data) => {
        this.data = data;
      });
      // set collection to local storage
      if(localStorage.getItem('collection')){
        this.collection = JSON.parse(localStorage.getItem('collection'))
      }
  },
  methods: {
    formatId(id){
      // if id contains characters, return id
      if(isNaN(id)){
        return id
      }
      return id.toString().padStart(3, '0')
    },
    addToCollection(id){
      this.collection.push(id)
      this.save()
    },
    removeFromCollection(id){
      if(this.collection.includes(id)){
        this.collection.splice(this.collection.indexOf(id), 1)
      }
      this.save()
    },
    countInCollection(id){
      return this.collection.filter(item => item === id).length
    },
    matchSearch(items, search){
      // return filtered items based on search
      if(search == "Collected" || search == "collected"){
        // if search is equal to "mine", return items in collection
        return items.filter(item => {
          return this.collection.includes(item.id)
        })
      }
      if(search == "Repeating" || search == "repeating"){
        // if search is equal to "repeating", return items which are in collection more than once
        return items.filter(item => {
          return this.collection.filter(i => i === item.id).length > 1
        })
      }
      if(search == "Missing" || search == "missing"){
        // if search is equal to "missing", return items which are not in collection
        return items.filter(item => {
          return !this.collection.includes(item.id)
        })
      }
      if(search){
        // if search contains text, filter items
        // if search is equal to item.id or item.series, return item
        return items.filter(item => {
          return this.formatId(item.id.toString()).includes(search) || item.id.toString().includes(search) || item.name.toLowerCase().includes(search.toLowerCase()) || item.series.toLowerCase().includes(search.toLowerCase())
        })
      } else {
        return items
      }
    },
    save(){
      // save collection to local storage
      localStorage.setItem('collection', JSON.stringify(this.collection))
      localStorage.setItem('last_updated', new Date())
      this.lastUpdated = this.dateToAgo(localStorage.getItem('last_updated'))
    },
    calcUniqueAmiibos(){
      // return unique amiibos
      return this.collection.filter((item, index) => {
        return this.collection.indexOf(item) === index
      }).length
    },
    dateToAgo(date){
      // return date in 1 seconds ago, 1 minutes ago, 1 hours ago, 1 days ago, 1 months ago, 1 years ago etc
      const seconds = Math.floor((new Date() - new Date(date)) / 1000);
      let interval = Math.floor(seconds / 31536000);
      if (interval > 1) {
        return interval + " years ago";
      }
      interval = Math.floor(seconds / 2592000);
      if (interval > 1) {
        return interval + " months ago";
      }
      interval = Math.floor(seconds / 86400);
      if (interval > 1) {
        return interval + " days ago";
      }
      interval = Math.floor(seconds / 3600);
      if (interval > 1) {
        return interval + " hours ago";
      }
      interval = Math.floor(seconds / 60);
      if (interval > 1) {
        return interval + " minutes ago";
      }
      return Math.floor(seconds) + " seconds ago";
    }
  }
}
</script>

<style>

html,body{
  font-family: Nunito, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #282c35;
  color: #DDD;
}
#app {
  margin-top: 60px;
}
.grayText {
  color: #999;
}
.card {
  margin-bottom: 20px;
  border: 0px;
  background: transparent;
  color: #DDD;
}
.card-img-top {
  background: transparent;
  cursor: pointer;
}
.card-body{
  text-align: center;
}
.grayscale {
  filter: grayscale(95%);
  transition: 0.2s;
}
.grayscale:hover {
  filter: grayscale(0%);
}
.filterLink {
  cursor: pointer;
}
</style>
